import React, { useEffect, useState } from "react";

import styled from "@emotion/styled";

import { confirmNewsletterDoubleOptIn } from "../../../components/pages/Shop/api";
import ShopBaseTemplate from "../../../components/pages/Shop/ShopBaseTemplate";
import LoadingIndicator from "../../../components/shared/LoadingIndicator";
import NewHeadline from "../../../components/shared/NewHeadline";
import { getUrlParam } from "../../../utils/urlParams";

const NewsletterSubscribe = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(true);

    const Centered = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
    `;

    const Content = styled.section`
        max-width: 600px;
    `;

    useEffect(() => {
        // Get the shopware confirm params (redirected from the OptIn E-Mail)
        const emailHash = getUrlParam("em");
        const hash = getUrlParam("hash");
        // Confirm  Opt-In
        const confirmOptIn = async () => {
            try {
                if (emailHash && hash) {
                    await confirmNewsletterDoubleOptIn(emailHash, hash);
                    setHasError(false);
                }
            } catch (error) {
                setHasError(true);
            } finally {
                setIsLoading(false);
            }
        };
        void confirmOptIn();
    }, []);

    if (isLoading) {
        return (
            <ShopBaseTemplate>
                <Centered>
                    <Content>
                        <LoadingIndicator />
                    </Content>
                </Centered>
            </ShopBaseTemplate>
        );
    }

    if (hasError) {
        return (
            <ShopBaseTemplate>
                <Centered>
                    <Content>
                        <NewHeadline>
                            Newsletter Opt-In fehlgeschlagen
                        </NewHeadline>
                        Dein Newsletteranmeldung ist ungültig oder wurde bereits
                        bestätigt.
                    </Content>
                </Centered>
            </ShopBaseTemplate>
        );
    }

    return (
        <ShopBaseTemplate>
            <Centered>
                <Content>
                    <NewHeadline>Newsletter Opt-In erfolgreich</NewHeadline>
                    Deine Newsletteranmeldung wurde bestätigt.
                </Content>
            </Centered>
        </ShopBaseTemplate>
    );
};

export default NewsletterSubscribe;
